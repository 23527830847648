import customFetch from "../../utils/axios";

export const getAllWorkedHourThunk = async (userId, thunkAPI) => {
  try {
    const resp = await customFetch.post(`/workcheckinout/forall`, userId);
    return resp.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.msg);
  }
};
export const getEmployeesForUserThunk = async (userId, thunkAPI) => {
  try {
    const resp = await customFetch.post(
      "/workcheckinout/getemployeeforuser",
      userId
    );
    return resp.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.msg);
  }
};

export const getAllWorkedHourForEmployeeThunk = async (payload, thunkAPI) => {
  try {
    const resp = await customFetch.post(
      `/workcheckinout/getcheckinoutforemployee`,
      payload
    );
    return resp.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.msg);
  }
};
