import { FaHourglassStart, FaHourglassEnd, FaStopwatch } from "react-icons/fa";
import Wrapper from "../assets/wrappers/WorkedHour";
import moment from "moment";
import "moment/locale/da";
import WorkedHourInfo from "./WorkedHourInfo";
import UpdateHour from "./UpdateHour";
const WorkPlanUpdate = ({ id, name, start, end }) => {
  moment.locale("da");
  const fra = moment(start);
  const til = moment(end);
  const duration = moment.duration(til.diff(fra));

  // Calculate the hours and minutes
  const hours = Math.floor(duration.asHours());
  const minutes = duration.minutes();

  // Format the result as hours and minutes
  const formattedResult = `${hours}:${minutes < 10 ? "0" : ""}${minutes}`;

  return (
    <Wrapper>
      <header>
        <div className="info">
          <h5>{name}</h5>
        </div>
      </header>
      <div className="content">
        <div className="content-center">
          <WorkedHourInfo
            icon={<FaHourglassStart />}
            text={fra.format("Do MMM YYYY, H:mm")}
          />
          <WorkedHourInfo
            icon={<FaHourglassEnd />}
            text={til.format("Do MMM YYYY, H:mm")}
          />
          <WorkedHourInfo icon={<FaStopwatch />} text={formattedResult} />
        </div>
      </div>
      <UpdateHour name={name} id={id} />
    </Wrapper>
  );
};
export default WorkPlanUpdate;
