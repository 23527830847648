import { useState } from "react";
import { FormRow } from "../../components";
import Wrapper from "../../assets/wrappers/RegisterPage";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  loginEmployee,
  registerEmployee,
} from "../../features/employee/employeeSlice";

const initialState = {
  name: "",
  email: "",
  password: "",
  isMember: true,
  isAdmin: false,
};

function AddEmployee() {
  const [values, setValues] = useState(initialState);
  const { isLoading } = useSelector((store) => store.employee);
  const user = useSelector((store) => store.user);
  const employee = useSelector((store) => store.employee.employee);
  const dispatch = useDispatch();

  const handleChange = (e) => {
    const name = e.target.name;
    let value = e.target.value;
    if (e.target.name === "isAdmin") {
      value = e.target.checked;
    }
    setValues({ ...values, [name]: value });
  };
  const onSubmit = (e) => {
    e.preventDefault();
    const { name, email, password, isMember, isAdmin } = values;
    const userId = user.user.id;

    if (!email || !password || (!isMember && !name)) {
      toast.error("Please fill out all fields");
      return;
    }
    if (isMember) {
      dispatch(loginEmployee({ email: email, password: password }));
      return;
    }
    dispatch(registerEmployee({ name, email, password, isAdmin, userId }));
  };

  const toggleMember = () => {
    setValues({ ...values, isMember: !values.isMember });
  };

  const isAdmin = () => {
    const isAdmin = employee ? employee.isAdmin : true;
    if (isAdmin) {
      return (
        <p>
          {values.isMember ? "Tilføj medarbejder?" : "Er registeret?"}
          <button type="button" onClick={toggleMember} className="member-btn">
            {values.isMember ? "Register" : "Login"}
          </button>
        </p>
      );
    }
  };
  return (
    <Wrapper className="full-page" style={{ marginTop: "-200px" }}>
      <form className="form" onSubmit={onSubmit}>
        {/* <Logo /> */}
        <h3>{values.isMember ? "Logind" : "Register"}</h3>
        {/* name field */}
        {!values.isMember && (
          <FormRow
            type="text"
            name="name"
            value={values.name}
            handleChange={handleChange}
          />
        )}
        {/* email field */}
        <FormRow
          type="email"
          name="email"
          value={values.email}
          handleChange={handleChange}
        />
        {/* password field */}
        <FormRow
          type="password"
          name="password"
          value={values.password}
          handleChange={handleChange}
        />
        {/* isadmin field*/}
        {!values.isMember && (
          <FormRow
            labelText="Admin ?"
            type="checkbox"
            name="isAdmin"
            handleChange={handleChange}
          />
        )}
        <button type="submit" className="btn btn-block" disabled={isLoading}>
          {isLoading ? "loading..." : "submit"}
        </button>
        {isAdmin()}
      </form>
    </Wrapper>
  );
}
export default AddEmployee;
