import { Route, Routes, BrowserRouter } from "react-router-dom";
import "./App.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  CheckIn,
  CheckOut,
  MakeWorkPlan,
  WorkPlan,
  SharedLayout,
  HoursWorked,
  AddEmployee,
  UpdateWorkplan,
} from "./pages/dashboard";
import { Landing, Register, Error, ProtectedRoute } from "./pages";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <SharedLayout />
            </ProtectedRoute>
          }
        >
          <Route index element={<WorkPlan />} />
          <Route path="checkin" element={<CheckIn />} />
          <Route path="checkout" element={<CheckOut />} />
          <Route path="makeworkplan" element={<MakeWorkPlan />} />
          <Route path="hoursworked" element={<HoursWorked />} />
          <Route path="addemployee" element={<AddEmployee />} />
          <Route path="updateworkplan" element={<UpdateWorkplan />} />
        </Route>
        <Route path="landing" element={<Landing />} />
        <Route path="/register" element={<Register />} />
        <Route path="*" element={<Error />} />
        <Route />
      </Routes>
      <ToastContainer position="top-center" />
    </BrowserRouter>
  );
}

export default App;
