import WorkPlanUpdate from "./WorkPlanUpdate";
import Wrapper from "../assets/wrappers/WorkedHourContainer";
import { useSelector } from "react-redux";
import Loading from "./Loading";

const WorkPlanContainer = () => {
  const { lastworkevents, isLoading } = useSelector((store) => store.workEvent);

  if (isLoading) {
    return <Loading center />;
  }

  if (lastworkevents.length === 0) {
    return (
      <Wrapper>
        <h2>Der er ikke registeret arbejd i perioden...</h2>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <div className="jobs">
        {lastworkevents.map((workedHour) => {
          return <WorkPlanUpdate key={workedHour.id} {...workedHour} />;
        })}
      </div>
    </Wrapper>
  );
};
export default WorkPlanContainer;
