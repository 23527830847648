import customFetch, { checkForUnauthorizedResponse } from "../../utils/axios";
import { clearValues } from "./workEventSlice";

export const getAllWorkEventsThunk = async (userId, thunkAPI) => {
  try {
    const resp = await customFetch.get(`/workevent/${userId}`);
    return resp.data;
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI);
  }
};
export const getLast30EventsThunk = async (payload, thunkAPI) => {
  try {
    const resp = await customFetch.get(`/workevent/lastevents/${payload}`);
    return resp.data;
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI);
  }
};
export const createWorkEventThunk = async (workEvent, thunkAPI) => {
  try {
    const resp = await customFetch.post("/workevent", workEvent);
    thunkAPI.dispatch(clearValues());
    return resp.data.msg;
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI);
  }
};
export const deleteWorkEventThunk = async (workEventId, thunkAPI) => {
  //   thunkAPI.dispatch(showLoading());
  //   try {
  //     const resp = await customFetch.delete(`/jobs/${jobId}`);
  //     thunkAPI.dispatch(getAllJobs());
  //     return resp.data.msg;
  //   } catch (error) {
  //     thunkAPI.dispatch(hideLoading());
  //     return checkForUnauthorizedResponse(error, thunkAPI);
  //   }
};
export const editWorkEventThunk = async (payload, thunkAPI) => {
  try {
    const resp = await customFetch.patch("/workevent/workeventupdate", payload);
    return resp;
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI);
  }
};
