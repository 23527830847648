import { FormRow } from ".";
import Wrapper from "../assets/wrappers/DashboardFormPage";
import { toast } from "react-toastify";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  createWorkCheckin,
  createWorkCheckout,
} from "../features/workCheckinAndOut/workCheckinOutSlice";

const initialState = {
  email: "",
  password: "",
};

function ChecksForm({ layout }) {
  const [values, setValues] = useState(initialState);
  const { isLoading } = useSelector((store) => store.employee);
  const { id } = useSelector((store) => store.user.user);
  const dispatch = useDispatch();
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setValues({ ...values, [name]: value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const { email, password } = values;
    if (!email || !password) {
      toast.error("Udfyld alle felter");
      return;
    }
    if (layout === "checkin") {
      dispatch(createWorkCheckin({ email: email, password, userId: id }));
    }
    if (layout === "checkout") {
      dispatch(createWorkCheckout({ email: email, password, userId: id }));
    }
  };
  return (
    <Wrapper>
      <form className="form">
        <h3>{layout}</h3>
        <div className="form-center">
          {/* email */}
          <FormRow
            type="email"
            name="email"
            value={values.email}
            handleChange={handleChange}
          />
          {/* password */}
          <FormRow
            type="password"
            name="password"
            value={values.password}
            handleChange={handleChange}
          />
          <div className="btn-container">
            <button
              type="submit"
              className="btn btn-block submit-btn"
              onClick={onSubmit}
              disabled={isLoading}
            >
              {layout}
            </button>
          </div>
        </div>
      </form>
    </Wrapper>
  );
}
export default ChecksForm;
