import { IoBarChartSharp, IoLogIn, IoLogOut } from "react-icons/io5";
import { FaUserPlus, FaCalendarPlus, FaCalendarAlt } from "react-icons/fa";

const links = [
  { id: 1, text: "ugeplan", path: "/", icon: <FaCalendarAlt /> },
  {
    id: 2,
    text: "opret ugeplan",
    path: "makeworkplan",
    icon: <FaCalendarPlus />,
  },
  { id: 3, text: "checkind", path: "checkin", icon: <IoLogIn /> },
  { id: 4, text: "checkud", path: "checkout", icon: <IoLogOut /> },
  {
    id: 5,
    text: "arbejdet timer",
    path: "hoursworked",
    icon: <IoBarChartSharp />,
  },
  {
    id: 6,
    text: "logind/tilføj medarbejder",
    path: "addemployee",
    icon: <FaUserPlus />,
  },
];

export default links;
