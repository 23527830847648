import React from "react";
import { WorkPlanContainer } from "../../components";

const UpdateWorkplan = () => {
  return (
    <>
      <div>UpdateWorkplan</div>
      <WorkPlanContainer />
    </>
  );
};

export default UpdateWorkplan;
