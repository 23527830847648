import FormRowDate from "./FormRowDate";
import { useDispatch, useSelector } from "react-redux";
import {
  handleChangeStart,
  handleChangeEnd,
  editWorkEvent,
} from "../features/workEvent/workEventSlice.js";
import { toast } from "react-toastify";
const UpdateHour = ({ name, id }) => {
  const { isLoading, start, end } = useSelector((store) => store.workEvent);
  // const selectedEmployee = employees.find((e) => e.name === name);

  const userId = useSelector((store) => store.user.user.id);
  console.log("user id:", userId);
  const dispatch = useDispatch();
  const handleDateInputFra = (e) => {
    dispatch(handleChangeStart(e));
  };
  const handleDateInputTil = (e) => {
    dispatch(handleChangeEnd(e));
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    if (!start || !end) {
      toast.error("Please fill out all fields");
      return;
    }

    // dispatch(updateHour({ name: name, start: start, end: end, id: id }));
    // dispatch(
    //   getAllWorkedHourForEmployee({
    //     userId: userId,
    //     employeeId: id,
    //     fra,
    //     til,
    //   })
    // );
    dispatch(
      editWorkEvent({ name: name, start: start, end: end, id: id })
    ).catch((error) => {
      // Handle any errors that occur during editworkevent
      console.error("Error updating hour:", error);
    });
  };
  return (
    <div className="content">
      <div className="content-center">
        {/* start */}
        <FormRowDate
          name="start"
          labelText="Start"
          value={start}
          handleChange={handleDateInputFra}
        />
        {/* end */}
        <FormRowDate
          name="slut"
          labelText="Slut"
          value={end}
          handleChange={handleDateInputTil}
        />
        <div className="btn-container">
          <button
            type="submit"
            className="btn btn-block submit-btn"
            onClick={handleSubmit}
            disabled={isLoading}
          >
            submit
          </button>
        </div>
      </div>
    </div>
  );
};
export default UpdateHour;
