import React, { useEffect, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import Wrapper from "../../assets/wrappers/DashboardFormPage";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllWorkevents,
  getLast30Events,
} from "../../features/workEvent/workEventSlice";
import { getAllWorkedHour } from "../../features/workedHour/workedHourSlice";
import { Link } from "react-router-dom";

const localizer = momentLocalizer(moment);

const WorkPlan = () => {
  const [eventst, setEventst] = useState([]);

  const { workevents } = useSelector((store) => store.workEvent);
  const { workedHoursForAll } = useSelector((store) => store.workedHour);
  const events = workevents.map((event) => ({
    ...event,
    start: new Date(
      Date.UTC(
        parseInt(event.start.substring(0, 4)), // Year
        parseInt(event.start.substring(5, 7)) - 1, // Month (0-indexed)
        parseInt(event.start.substring(8, 10)), // Day
        parseInt(event.start.substring(11, 13)), // Hour
        parseInt(event.start.substring(14, 16)), // Minute
        parseInt(event.start.substring(17, 19)), // Second
        parseInt(event.start.substring(20, 23)) // Millisecond
      )
    ),
    end: new Date(
      Date.UTC(
        parseInt(event.end.substring(0, 4)), // Year
        parseInt(event.end.substring(5, 7)) - 1, // Month (0-indexed)
        parseInt(event.end.substring(8, 10)), // Day
        parseInt(event.end.substring(11, 13)), // Hour
        parseInt(event.end.substring(14, 16)), // Minute
        parseInt(event.end.substring(17, 19)), // Second
        parseInt(event.end.substring(20, 23)) // Millisecond
      )
    ),
    title: event.title,
  }));

  const id = useSelector((store) => store.user.user.id);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllWorkevents(id));
    dispatch(getAllWorkedHour({ userId: id }));
  }, [dispatch, id]);

  const handleSubmit = () => {
    dispatch(getLast30Events(id));
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      const updatedWorkedHours = workedHoursForAll.map((event) => {
        if (event.end == null) {
          return {
            ...event,
            end: new Date(),
            color: "green",
          };
        }
        return event;
      });

      const eventsWorked = updatedWorkedHours.map((event) => ({
        ...event,
        start: new Date(event.start),
        end: new Date(event.end),
        title: event.name,
      }));
      const mergedEvents = [...events, ...eventsWorked];
      setEventst(mergedEvents);
    }, 2000); // Update every minute

    // Clean up the interval
    return () => clearInterval(intervalId);
  }, [events, workedHoursForAll]);
  const eventStyleGetter = (event, start, end, isSelected) => {
    let style = {};

    if (event.color === "green") {
      style.backgroundColor = "green";
    }

    return {
      style,
    };
  };

  return (
    <Wrapper style={{ height: "95vh" }}>
      <Link
        to="/updateworkplan"
        className="btn btn-rediger"
        onClick={handleSubmit}
      >
        Rediger workplan
      </Link>
      <Calendar
        eventPropGetter={eventStyleGetter}
        localizer={localizer}
        events={eventst}
        defaultView="week"
      />
    </Wrapper>
  );
};

export default WorkPlan;
// check in yapilinca yesil olup gosteriyormu bak yoksa omu hallet

// const eventse = [
//   {
//     title: "veli",
//     start: new Date("Febuary 24, 2024 16:00:00"),
//     end: new Date("Febuary 24, 2024 18:30:00"),
//   },
//   {
//     title: "Muhammed",
//     start: new Date("Febuary 21, 2024 16:00:00"),
//     // end: new Date("January 21, 2024 20:30:00"),
//   },
// ];
