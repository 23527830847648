import main from "../assets/images/main.svg";
import Wrapper from "../assets/wrappers/LandingPage";
// import { Logo } from '../components';
import { Link } from "react-router-dom";

const Landing = () => {
  return (
    <Wrapper>
      <nav>{/* <Logo /> */}</nav>
      <div className="container page">
        {/* info */}
        <div className="info">
          <h1>
            Employee <span>management</span> system
          </h1>
          <p>
            Employee management system  offers a streamlined solution for tracking employee work hours and managing schedules. 
	    Employees can quickly check in and out, while managers have real-time visibility over attendance and work hours. 
	    The shared dashboard provides both employees and managers with essential tools: 
	    employees can view their schedules and logged hours, while managers can create and adjust work plans.
          </p>
          <Link to="/register" className="btn btn-hero">
            Login
          </Link>
        </div>
        <img src={main} alt="job hunt" className="img main-img" />
      </div>
    </Wrapper>
  );
};

export default Landing;
