import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  createWorkEventThunk,
  deleteWorkEventThunk,
  editWorkEventThunk,
  getAllWorkEventsThunk,
  getLast30EventsThunk,
} from "./workEventThunk";

const initialState = {
  isLoading: false,
  name: "",
  start: new Date(),
  end: new Date(),
  til: new Date(),
  fra: new Date(),
  workevents: [],
  lastworkevents: [],
};
export const getLast30Events = createAsyncThunk(
  "workEvent/getLast30Events",
  getLast30EventsThunk
);

export const getAllWorkevents = createAsyncThunk(
  "workEvent/getAllWorkEvent",
  getAllWorkEventsThunk
);
export const createWorkEvent = createAsyncThunk(
  "workEvent/createWorkEvent",
  createWorkEventThunk
);

export const deleteWorkEvent = createAsyncThunk(
  "workEvent/deleteWorkEvent",
  deleteWorkEventThunk
);

export const editWorkEvent = createAsyncThunk(
  "workEvent/editWorkEvent",
  editWorkEventThunk
);

const workEventSlice = createSlice({
  name: "workEvent",
  initialState,
  reducers: {
    handleChange: (state, { payload: { name, value } }) => {
      state[name] = value;
    },
    handleChangeStart: (state, { payload: value }) => {
      state.start = value;
    },
    handleChangeEnd: (state, { payload: value }) => {
      state.end = value;
    },
    clearValues: () => {
      return {
        ...initialState,
      };
    },
    setEditWorkEvent: (state, { payload }) => {
      return { ...state, isEditing: true, ...payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createWorkEvent.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createWorkEvent.fulfilled, (state) => {
        state.isLoading = false;
        toast.success("Plan tilføjet");
      })
      .addCase(createWorkEvent.rejected, (state, { payload }) => {
        state.isLoading = false;
        toast.error(payload);
      })
      .addCase(deleteWorkEvent.fulfilled, (state, { payload }) => {
        toast.success(payload);
      })
      .addCase(deleteWorkEvent.rejected, (state, { payload }) => {
        toast.error(payload);
      })
      .addCase(editWorkEvent.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(editWorkEvent.fulfilled, (state) => {
        state.isLoading = false;
        toast.success("Plan redigeret");
      })
      .addCase(editWorkEvent.rejected, (state, { payload }) => {
        state.isLoading = false;
        toast.error(payload);
      })
      .addCase(getAllWorkevents.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllWorkevents.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.workevents = payload.workevents;
      })
      .addCase(getAllWorkevents.rejected, (state, { payload }) => {
        state.isLoading = false;
        toast.error(payload);
      })
      .addCase(getLast30Events.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getLast30Events.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        console.log(payload);
        state.lastworkevents = payload.workevents;
      })
      .addCase(getLast30Events.rejected, (state, { payload }) => {
        state.isLoading = false;
        toast.error(payload);
      });
  },
});

export const {
  handleChange,
  handleChangeStart,
  handleChangeEnd,
  clearValues,
  setEditWork,
  handleChangeTil,
  handleChangeFra,
} = workEventSlice.actions;

export default workEventSlice.reducer;
