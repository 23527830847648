import { configureStore } from "@reduxjs/toolkit";
import userSlice from "./features/user/userSlice";
import employeeSlice from "./features/employee/employeeSlice";
import workEventSlice from "./features/workEvent/workEventSlice";
import workCheckinOutSlice from "./features/workCheckinAndOut/workCheckinOutSlice";
import workedHourSlice from "./features/workedHour/workedHourSlice";
export const store = configureStore({
  reducer: {
    user: userSlice,
    employee: employeeSlice,
    workEvent: workEventSlice,
    workCheckinOut: workCheckinOutSlice,
    workedHour: workedHourSlice,
  },
});
