import { FormRowSelect, FormRowOnlyDate } from "./";
import Wrapper from "../assets/wrappers/DashboardFormPage";
import { useSelector, useDispatch } from "react-redux";

import {
  handleChange,
  handleChangeStart,
  handleChangeEnd,
  getAllWorkedHourForEmployee,
  getEmployeesForUser,
} from "../features/workedHour/workedHourSlice";
import { useEffect } from "react";

const SearchContainer = () => {
  const { isLoading, name, fra, til, employees } = useSelector(
    (store) => store.workedHour
  );
  const { id } = useSelector((store) => store.user.user);
  const dispatch = useDispatch();
  const handleSubmit = (e) => {
    e.preventDefault();
    const selectedEmployee = employees.find((e) => e.name === name);
    dispatch(
      getAllWorkedHourForEmployee({
        userId: id,
        employeeId: selectedEmployee.id,
        fra,
        til,
      })
    );
  };

  const handleNameInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    dispatch(handleChange({ name, value }));
  };
  const handleDateInputStart = (e) => {
    dispatch(handleChangeStart(e));
  };
  const handleDateInputEnd = (e) => {
    dispatch(handleChangeEnd(e));
  };

  // const isAdmin = () => {
  //   if (employee.isAdmin) {
  //     return (
  //       <button
  //         className="btn btn-block submit-btn"
  //         onClick={dispatch(getAllWorkedHour)}
  //         disabled={isLoading}
  //       >
  //         Alle medarbejder
  //       </button>
  //     );
  //   }
  // };
  useEffect(() => {
    dispatch(getEmployeesForUser({ userId: id }));
  }, [dispatch, id]);

  return (
    <Wrapper>
      <form className="form">
        <h3> Vælg periode</h3>
        <div className="form-center">
          {/* names */}
          <FormRowSelect
            name="name"
            value={name}
            labelText="navn"
            handleChange={handleNameInput}
            list={employees}
          />
          {/* fra */}
          <FormRowOnlyDate
            name="fra"
            labelText="Fra"
            value={fra}
            handleChange={handleDateInputStart}
          />
          {/* til */}
          <FormRowOnlyDate
            name="til"
            labelText="Til"
            value={til}
            handleChange={handleDateInputEnd}
          />

          <div className="btn-container">
            {/* <button
              type="button"
              className="btn btn-block clear-btn"
              onClick={() => dispatch(clearValues())}
            >
              clear
            </button> */}
            <button
              type="submit"
              className="btn btn-block submit-btn"
              onClick={handleSubmit}
              disabled={isLoading}
            >
              submit
            </button>
          </div>
          {/* {isAdmin()} */}
        </div>
      </form>
    </Wrapper>
  );
};
export default SearchContainer;
