import { FormRow, FormRowDate } from "../../components";
import Wrapper from "../../assets/wrappers/DashboardFormPage";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  handleChange,
  clearValues,
  handleChangeStart,
  handleChangeEnd,
  createWorkEvent,
} from "../../features/workEvent/workEventSlice";

const MakeWorkPlan = () => {
  const { isLoading, name, start, end } = useSelector(
    (store) => store.workEvent
  );
  const { id } = useSelector((store) => store.user.user);
  const dispatch = useDispatch();
  const handleSubmit = (e) => {
    e.preventDefault();

    if (!name || !start || !end) {
      toast.error("Please fill out all fields");
      return;
    }

    dispatch(
      createWorkEvent({ name: name, start: start, end: end, userId: id })
    );
  };

  const handleJobInput = (e) => {
    console.log(e);
    const name = e.target.name;
    const value = e.target.value;
    dispatch(handleChange({ name, value }));
  };
  const handleDateInputStart = (e) => {
    dispatch(handleChangeStart(e));
  };
  const handleDateInputEnd = (e) => {
    dispatch(handleChangeEnd(e));
  };

  return (
    <Wrapper>
      <form className="form">
        <h3> tilføj til ugeplan</h3>
        <div className="form-center">
          {/* position */}
          <FormRow
            type="text"
            name="name"
            value={name}
            labelText="navn"
            handleChange={handleJobInput}
          />
          {/* start */}
          <FormRowDate
            name="start"
            labelText="Start"
            value={start}
            handleChange={handleDateInputStart}
          />
          {/* end */}
          <FormRowDate
            name="slut"
            labelText="Slut"
            value={end}
            handleChange={handleDateInputEnd}
          />

          <div className="btn-container">
            <button
              type="button"
              className="btn btn-block clear-btn"
              onClick={() => dispatch(clearValues())}
            >
              clear
            </button>
            <button
              type="submit"
              className="btn btn-block submit-btn"
              onClick={handleSubmit}
              disabled={isLoading}
            >
              submit
            </button>
          </div>
        </div>
      </form>
    </Wrapper>
  );
};
export default MakeWorkPlan;
